import React, { useEffect, useRef, useState } from 'react';
import './CircularScore.css';

function easeInOutQuad(t) {
  return t < 0.5
    ? 2 * t * t
    : -1 + (4 - 2 * t) * t;
}

const CircularScore = ({ score }) => {
    const [animatedScore, setAnimatedScore] = useState(0);
    const radius = 40;                         
    const circumference = 2 * Math.PI * radius;
    const duration = 1000;  
    const frameIdRef = useRef(null);

    useEffect(() => {
        let startTime = 0;

        const animate = (timestamp) => {
            if (startTime === 0) {
                startTime = timestamp;
            }
            const elapsed = timestamp - startTime;

            // rawProgress is a linear fraction 0..1
            let rawProgress = elapsed / duration;
            if (rawProgress > 1) rawProgress = 1;

            // Easing function (optional)
            const progress = easeInOutQuad(rawProgress);

            // Current value is fraction of final score
            const currentValue = Math.round(score * progress);
            setAnimatedScore(currentValue);

            // If not finished, request next frame
            if (rawProgress < 1) {
                frameIdRef.current = requestAnimationFrame(animate);
            }
        };

        frameIdRef.current = requestAnimationFrame(animate);

        return () => {
            if (frameIdRef.current) {
                cancelAnimationFrame(frameIdRef.current);
            }
        };
    }, [score]);

    // Convert animatedScore to circle offset
    const fraction = animatedScore / 100;
    const offset = circumference - fraction * circumference;

    return (
        <svg width="100" height="100" className="circular-progress">
            <circle
                className="background-circle"
                cx="50"
                cy="50"
                r={radius}
                fill="transparent"
                stroke="white"
                strokeWidth="8"
            />
            <circle
                className="progress-circle"
                cx="50"
                cy="50"
                r={radius}
                fill="transparent"
                stroke="orange"
                strokeWidth="8"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                className="score-text"
            >
                {animatedScore}
            </text>
        </svg>
    );
};

export default CircularScore;
