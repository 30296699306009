// Modal.js
import React from 'react';
import './Modal.css'; // We'll define some styles below

function Modal({ children, onClose }) {
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={stopPropagation}>
        {/* <button className="modal-close-button" onClick={onClose}>
          ✕
        </button> */}
        {children}
      </div>
    </div>
  );
}

export default Modal;
