import React, { useState } from 'react';
import './Plans.css';
import { FaCheckCircle, FaCoins, FaHeart, FaLightbulb, FaStar } from 'react-icons/fa';
import { doc, collection, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import { getAuth } from 'firebase/auth';
import { applyLatestOrder } from './firebase'; // Function to update user status based on orders

const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState('Monthly');

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  const redirectToLemonSqueezy = () => {
    const checkoutLink =
      selectedPlan === 'Monthly'
        ? 'https://scoreme.lemonsqueezy.com/buy/8c2adcf0-5b6e-4804-a017-556f33fc75e4'
        : 'https://scoreme.lemonsqueezy.com/buy/8a8f31c3-02ac-4ae9-9b66-834d9a91dffc';

    window.location.href = checkoutLink; // Redirect user to LemonSqueezy checkout
  };

  return (
    <div className="pricing-page">
      <h1>Free Access</h1>
      <div className="desciptionAccessArea">
        <p className="desciptionAccess">
        Score Me is currently free to use, allowing you to post one score daily. 
    Our goal is to build a strong community first, so for now, there are no upgrades or payment options available. 
    Enjoy the tools we provide and join us in shaping the future of product scoring!
        </p>
      </div>
      <div>If you want to support us, vote for us:</div>
      <div className="desciptionAccessArea">
        <p>Product Hunt</p>
        <p>IndieHacker</p>
        <p>Donate</p>
      </div>
      
      {/* <div className="plan-selector">
        <div className="bubble-container">
          <button
            className={selectedPlan === 'Monthly' ? 'active' : ''}
            onClick={() => handlePlanChange('Monthly')}
          >
            Monthly
          </button>
          <button
            className={selectedPlan === 'Yearly' ? 'active' : ''}
            onClick={() => handlePlanChange('Yearly')}
          >
            Annual
          </button>
        </div>
      </div>

      <div className="pricing-cards">
        <PriceCard
          icon={<FaStar />}
          label="Most popular"
          title="Premium"
          description={
            selectedPlan === 'Monthly'
              ? 'Premium plan. Stay tuned with us and build community (monthly subscription).'
              : 'Premium plan. Stay tuned with us and build community (annual subscription).'
          }
          price={selectedPlan === 'Monthly' ? '7.99' : '67'}
          period={selectedPlan === 'Monthly' ? '/month' : '/year (Save 30%)'}
          details={['Unlimited usage', '24/7 support', 'Verify post within 24 hours']}
          onClick={redirectToLemonSqueezy}
        />
      </div> */}
    </div>
  );
};

const PriceCard = ({ icon, label, title, description, price, period, details, onClick }) => {
  return (
    <div className="price-card">
      <div className="priceCardTop">
        <div>
          <div className="priceCardIcon">{icon}</div>
        </div>
        {label && (
          <div>
            <div className="priceCardLabel">{label}</div>
          </div>
        )}
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="pricePeriod">
        <h3>${price}</h3>
        <span>{period}</span>
      </div>
      <div className="divider"></div>
      <ul>
        {details.map((detail, index) => (
          <li key={index}>
            <FaCheckCircle />
            {detail}
          </li>
        ))}
      </ul>
      <div className="divider"></div>
      <button className="priceCardButton" onClick={onClick}>
        Get Started
      </button>
    </div>
  );
};

export default Plans;
