import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { auth, db } from "./firebase"; // Import Firestore instance
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore"; // Import Firestore functions

const checkPreLaunchStatus = async () => {
  const controlPanelRef = doc(db, "controlPanel", "8SGFUGkF19XbA9LgkVfj"); // Replace with the correct document ID
  const docSnap = await getDoc(controlPanelRef);
  if (docSnap.exists()) {
    return docSnap.data().Prelaunch || false;
  }
  console.error("No such document in controlPanel!");
  return false;
};

export const PrivateRoute = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPreLaunch, setIsPreLaunch] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchPreLaunchStatus = async () => {
      const prelaunchStatus = await checkPreLaunchStatus();
      setIsPreLaunch(prelaunchStatus);
    };

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      fetchPreLaunchStatus();
    });

    return unsubscribe;
  }, []);

  if (loading || isPreLaunch === null) return null; // Show loader until we know the state

  // Always allow access to Privacy and Terms pages
  const unrestrictedPaths = ["/privacy", "/terms-and-conditions"];
  if (unrestrictedPaths.includes(location.pathname)) {
    return <Outlet />;
  }

  // Redirect based on Prelaunch status
  if (isPreLaunch) return <Navigate to="/launch" />;
  return user ? <Outlet /> : <Navigate to="/" />;
};

// PublicRoute Component
export const PublicRoute = ({ restricted }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPreLaunch, setIsPreLaunch] = useState(null);

  useEffect(() => {
    const fetchPreLaunchStatus = async () => {
      const prelaunchStatus = await checkPreLaunchStatus();
      setIsPreLaunch(prelaunchStatus);
    };

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      fetchPreLaunchStatus(); // Fetch Prelaunch status after auth state
    });

    return unsubscribe;
  }, []);

  if (loading || isPreLaunch === null) return null; // Show loader until we know prelaunch status and auth state

  // Redirect based on Prelaunch status
  if (isPreLaunch) return <Navigate to="/launch" />;
  return user && restricted ? <Navigate to="/home" /> : <Outlet />;
};
