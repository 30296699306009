import React, { useState } from "react";
import { 
  auth, 
  googleAuthProvider, 
  twitterAuthProvider, 
  createOrUpdateUser 
} from "./firebase";
import { createUserWithEmailAndPassword, signInWithPopup, sendEmailVerification } from "firebase/auth";
import "./SignUp.css";
import animationData from './images/signup.json';
import Lottie from "react-lottie";
import XIcon from "./images/x-icon.svg";
import GoogleIcon from "./images/google-icon.webp";
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      // Register user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email
      await sendEmailVerification(user);
      setSuccessMessage("A verification email has been sent to your email address. Please verify your email before logging in.");

      // Create or update user in Firestore
      await createOrUpdateUser(user, {
        userName: username,
        newsletter,
      });

      console.log("User created and verification email sent:", user);
    } catch (err) {
      setError(err.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;

      await createOrUpdateUser(user);
      console.log("Signed in with Google:", user);
    } catch (err) {
      setError(err.message);
    }
  };

  const signInWithTwitter = async () => {
    try {
      const result = await signInWithPopup(auth, twitterAuthProvider);
      const user = result.user;

      await createOrUpdateUser(user);
      console.log("Signed in with Twitter:", user);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="signUpBox">
      <div 
        style={{ textAlign: "start", fontSize: "1rem", fontWeight: "600", cursor: "pointer" }} 
        onClick={() => navigate('/')}
      >
        Back
      </div>
      <div className="signUpForm">
        <div className="sign-up-form">
          <h2>Create your account on ScoreMe.io</h2>
          <p>
            <a href="/signin">Click here if you already have one</a>
          </p>
          {error && <p className="error">{error}</p>}
          {successMessage && <p className="success">{successMessage}</p>}

          <div className="socButtons">
            <button className="signSocButton" onClick={signInWithGoogle}>
              <img className="iconSocial" src={GoogleIcon} alt="Google" /> Sign up with Google
            </button>
            <button className="signSocButton" onClick={signInWithTwitter}>
              <img className="iconSocial" src={XIcon} alt="Twitter" /> Sign up with X
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="inputDivReg">
              <label>Email address</label>
              <input 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
            </div>
            <div className="inputDivReg">
              <label>Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="inputDivReg">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="inputDivReg">
              <label>Confirm your password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="inputDivReg">
              <label className="labelCheckbox">
                <div className="checkbox-wrapper-12">
                  <div className="cbx">
                    <input id="cbx-12" type="checkbox" />
                    <label htmlFor="cbx-12"></label>
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
                      <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <defs>
                      <filter id="goo-12">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur"></feGaussianBlur>
                        <feColorMatrix 
                          in="blur" 
                          mode="matrix" 
                          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7" 
                          result="goo-12">
                        </feColorMatrix>
                        <feBlend in="SourceGraphic" in2="goo-12"></feBlend>
                      </filter>
                    </defs>
                  </svg>
                </div>
                I want to receive the weekly newsletter 🥰
              </label>
            </div>
            <button type="submit">SIGN ME UP!</button>
          </form>
          <div className="alreadyLogin">
            Already have an account? <span onClick={() => navigate('/signin')}>Log in</span>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "20px" }}>
          <Lottie
            style={{ height: "auto" }}
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
