import React, { useEffect, useState } from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import Logo from './images/Logo.png';


function Footer() {
const navigate = useNavigate();

  return (
    <div className='footerLand'>
    <div className='footerRows'>
        <div className='footerColumns'>
        <div className='footerRow'>
            <div className='logoFooter'>
            <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
            </div>
            <div>
            ScoreMe.io is the ultimate platform for product evaluation and discovery. Our tool allows you to score and rate digital and physical products based on objective criteria, powered by AI insights. Whether you're a creator, entrepreneur, or consumer, you can easily share your product feedback, connect with our community, and gain visibility for your innovations. Post your products publicly, get likes, and rise in popularity with our Hall of Fame feature. Join ScoreMe.io today and make your product stand out in a competitive market!
            </div>
        </div>
        <div className='footerRow'>
            <div className='menuButtonItem'>
                <b>Score Me</b>
            </div>
            <div className='menuButtonItem'>
                How it works
            </div>
            <div className='menuButtonItem'>
                Score digital products
            </div>
            <div className='menuButtonItem' onClick={() => navigate('/terms-and-conditions')}>
                Terms & Conditions
            </div>
            <div className='menuButtonItem' onClick={() => navigate('/privacy')}>
                Privacy Policy
            </div>
            <div className='menuButtonItem'>
                Feed
            </div>
        </div>
        <div className='footerRow'>
            <div>
                <b>Social Networks</b>
            </div>
            <div className='menuButtonItem'>
                <a href='https://x.com/ScoreMeIo'>X</a>
            </div>
            <div className='menuButtonItem'>
                Instagram
            </div>
            <div className='menuButtonItem'>
                Facebook
            </div>
            <div className='menuButtonItem'>
                Email
            </div>
        </div>
        </div>
    </div>
    <div>
        © 2024 ScoreMe.io™. All Rights Reserved. Privacy Policy
    </div>
</div>
  );
}

export default Footer;
