import React, { useState } from "react";
import { auth, googleAuthProvider, twitterAuthProvider } from "./firebase";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import "./SignUp.css";
import animationData from './images/signup.json';
import Lottie from "react-lottie";
import XIcon from "./images/x-icon.svg";
import GoogleIcon from "./images/google-icon.webp";
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Sign in user with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      console.log("User signed in via email/password:", user);
      navigate("/home"); // Redirect after successful sign-in
    } catch (err) {
      setError(err.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;

      console.log("Signed in with Google:", user);
      navigate("/home"); // Redirect after successful sign-in
    } catch (err) {
      setError(err.message);
    }
  };

  const signInWithTwitter = async () => {
    try {
      const result = await signInWithPopup(auth, twitterAuthProvider);
      const user = result.user;

      console.log("Signed in with Twitter:", user);
      navigate("/home"); // Redirect after successful sign-in
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="signUpBox">
      <div style={{ textAlign: "start", fontSize: "1rem", fontWeight: "600", cursor: "pointer" }} onClick={() => navigate('/')}>Back</div>
      <div className="signUpForm">
        <div className="sign-up-form">
          <h2>Log in to ScoreMe.io</h2>
          <p>Enter your details to log in to your account</p>
          {error && <p className="error">{error}</p>}

          <div className="socButtons">
            <button className="signSocButton" onClick={signInWithGoogle}>
              <img className="iconSocial" src={GoogleIcon} alt="Google" /> Sign in with Google
            </button>
            <button className="signSocButton" onClick={signInWithTwitter}>
              <img className="iconSocial" src={XIcon} alt="Twitter" /> Sign in with X
            </button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="inputDivReg">
              <label>Email address</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="inputDivReg">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="havingTrouble">
            <span onClick={() => navigate('/restore-password')}>Having a trouble with sign in? </span>
          </div>
            <button type="submit">SIGN IN</button>
          </form>
          <div className="alreadyLogin">
            Don't have an account? <span onClick={() => navigate('/signup')}>Sign Up</span>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "20px" }}>
          <Lottie
            style={{ height: "auto" }}
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
