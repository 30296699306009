import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", width: "100%"}}>
    <div style={{ padding: '20px', lineHeight: '1.6', width: "50%", fontSize: "1rem" }}>
    <div style={{textAlign: "start", fontWeight: "600", cursor: "pointer"}} onClick={() => navigate('/')}>Back</div>
      <h1>Privacy Policy</h1>
      <p><strong>Last Updated:</strong> 09/01/2025</p>
      <p>
        Welcome to ScoreMe.io! At ScoreMe.io, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our platform.
      </p>
      <hr />

      <h2>1. Information We Collect</h2>
      <p>
        We collect the following types of information:
      </p>
      <ul style={{textAlign: "start"}}>
        <li><strong>Personal Information:</strong> Name, email address, and other details you provide when creating an account.</li>
        <li><strong>Usage Data:</strong> Information about how you use our platform, including pages visited, actions taken, and preferences set.</li>
      </ul>
      <hr />

      <h2>2. How We Use Your Information</h2>
      <p>
        We use your data to:
      </p>
      <ul style={{textAlign: "start"}}>
        <li>Provide and improve our platform and services.</li>
        <li>Personalize your experience based on your preferences.</li>
        <li>Send updates, promotional offers, and important account notifications.</li>
        <li>Ensure the security and integrity of our platform.</li>
      </ul>
      <hr />

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell your personal data. However, we may share your information with:
      </p>
      <ul style={{textAlign: "start"}}>
        <li>Service providers who help us operate our platform.</li>
        <li>Legal or regulatory authorities when required by law.</li>
        <li>Partners for analytics and marketing purposes (aggregated and anonymized data only).</li>
      </ul>
      <hr />

      <h2>4. Your Rights</h2>
      <p>
        You have the right to:
      </p>
      <ul style={{textAlign: "start"}}>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt-out of promotional communications.</li>
        <li>Request data portability or restriction of processing.</li>
      </ul>
      <p>
        To exercise these rights, please contact us at <a href="mailto:manager@scoreme.io">manager@scoreme.io</a>.
      </p>
      <hr />

      <h2>5. Data Security</h2>
      <p>
        We implement robust security measures to protect your information. However, no method of transmission or storage is completely secure, and we cannot guarantee absolute security.
      </p>
      <hr />

      <h2>7. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated "Last Updated" date. Continued use of our platform constitutes acceptance of these changes.
      </p>
      <hr />

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <ul style={{textAlign: "start"}}>
        <li><strong>Email:</strong> <a href="mailto:manager@scoreme.io">manager@scoreme.io</a></li>
      </ul>
      <p>
        Thank you for trusting ScoreMe.io. Join us today to make your product stand out in a competitive market!
      </p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
