import React, { useState } from 'react';
import './App.css';
import Rate from './Rate';
import Product from './Product'; // Ensure you import the Product component
import Menu from './Menu';
import Dashboard from './Dashboard';

function Main() {
  const [selectedOption, setSelectedOption] = useState('website');

  return (
    <div className='mainBodyBox'>
      <h1 className='mainTitle'>Rate your product!</h1>
        <p className='secondTitle'>Here's a comprehensive overview of your design with actionable feedback to help you improve.</p>
        <div className='selectorProduct'>
        
        </div>
        {selectedOption === 'website' ? <Rate /> : <Product />}
    </div>
  );
}

export default Main;
