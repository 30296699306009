import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase';
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  where
} from 'firebase/firestore';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';

import './Dashboard.css';
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json';
import CircularScore from './CircularScore'; // Import the CircularScore component

function Dashboard() {
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // For delete confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // For post modal
  const [showPostModal, setShowPostModal] = useState(false);
  const [aboutPostText, setAboutPostText] = useState('');
  const [currentPostId, setCurrentPostId] = useState(null);

  // ----------
  //  FETCH LOGIC
  // ----------
  const fetchResponses = useCallback(async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (user) {
      try {
        const q = query(
          collection(db, `history/${user.uid}/responses`),
          orderBy('dateResponse', 'desc'),
          where('saveResponse', '==', true) // Ensure saveResponse is boolean and true
        );
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          console.log('No documents found for the current user with saveResponse=true.');
        } else {
          console.log('Fetched Documents:', querySnapshot.docs);
        }
  
        const fetchedResponses = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          parsedTextResponse: processTextResponse(doc.data().textResponse)
        }));
  
        setResponses(fetchedResponses);
      } catch (error) {
        console.error('Error fetching responses:', error);
      }
    } else {
      console.error('No user is logged in.');
    }
    setIsLoading(false);
  }, []);
  
  console.log('Current User:', auth.currentUser);


  // ----------
  //  DELETE LOGIC (MODAL)
  // ----------
  function DeleteConfirmationModal({ isOpen, onDelete, onCancel }) {
    if (!isOpen) return null;
    return (
      <div className="modalBackground">
        <div className="modalContainer">
          <h2>Are you sure you want to delete this post?</h2>
          <div className="modalButtons">
            <button onClick={onDelete}>Yes</button>
            <button onClick={onCancel}>No</button>
          </div>
        </div>
      </div>
    );
  }

  const promptDelete = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (selectedId) {
      const user = auth.currentUser;
      if (user) {
        const responseRef = doc(db, `history/${user.uid}/responses`, selectedId);
        try {
          await updateDoc(responseRef, { saveResponse: false });
          console.log(`Save response for ${selectedId} updated to false`);
          fetchResponses();
        } catch (error) {
          console.error('Error updating document: ', error);
        }
      }
      setShowDeleteModal(false);
      setSelectedId(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedId(null);
  };

  // ----------
  //  POST LOGIC (MODAL)
  // ----------
  function PostModal({ isOpen, onPost, onCancel }) {
    const [localAboutPostText, setLocalAboutPostText] = useState('');
    const [localFeaturesText, setLocalFeaturesText] = useState('');
    const [selectedPaymentModel, setSelectedPaymentModel] = useState('');

    // NEW: For uploading the icon
    const [iconFile, setIconFile] = useState(null);
    const [uploadError, setUploadError] = useState('');

    // Validation
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
      const trimmedAboutText = localAboutPostText.trim();
      const trimmedFeaturesText = localFeaturesText.trim();

      // Validation rules:
      // aboutPost: 10 - 50 chars
      // featuresPost: 50 - 500 chars
      // paymentModel: must be selected
      // If you want different constraints, adjust them here
      setIsValid(
        trimmedAboutText.length >= 10 &&
          trimmedAboutText.length <= 50 &&
          trimmedFeaturesText.length >= 50 &&
          trimmedFeaturesText.length <= 500 &&
          selectedPaymentModel
      );
    }, [localAboutPostText, localFeaturesText, selectedPaymentModel]);

    // Handle user picking a file
    const handleFileChange = (e) => {
      setUploadError('');
      const file = e.target.files?.[0];
      if (!file) {
        setIconFile(null);
        return;
      }
      // Validate file type
      if (
        file.type !== 'image/png' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/jpg'
      ) {
        setUploadError('File must be a PNG or JPEG image.');
        setIconFile(null);
        return;
      }
      // Validate file size (<= 1 MB)
      const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB
      if (file.size > MAX_FILE_SIZE) {
        setUploadError('File is too large. Must be <= 1 MB.');
        setIconFile(null);
        return;
      }
      // If valid
      setIconFile(file);
    };

    const handlePost = async () => {
      if (!isValid) return;

      try {
        let iconPostUrl = '';
        // If user selected a file, upload to Firebase Storage
        if (iconFile) {
          const storage = getStorage(); // from firebase
          const user = auth.currentUser;
          // For example: "icons/[userUid]/responseIcons/<filename>"
          const fileRef = storageRef(
            storage,
            `icons/${user.uid}/responseIcons/${Date.now()}-${iconFile.name}`
          );

          // Perform the upload
          const uploadTask = uploadBytesResumable(fileRef, iconFile);
          // Wait until finished
          await new Promise((resolve, reject) => {
            uploadTask.on(
              'state_changed',
              () => {},
              reject,
              () => resolve()
            );
          });

          // Grab the download URL
          iconPostUrl = await getDownloadURL(fileRef);
        }

        // Pass all data (including iconPostUrl) back to parent
        await onPost({
          aboutPost: localAboutPostText.trim(),
          featuresPost: localFeaturesText.trim(),
          paymentModel: selectedPaymentModel,
          iconPostUrl
        });

        // Clear fields
        setLocalAboutPostText('');
        setLocalFeaturesText('');
        setSelectedPaymentModel('');
        setIconFile(null);
        setUploadError('');
      } catch (err) {
        console.error('Error uploading icon:', err);
        setUploadError('Failed to upload icon. Please try again.');
      }
    };

    // Reset fields when modal closes
    useEffect(() => {
      if (!isOpen) {
        setLocalAboutPostText('');
        setLocalFeaturesText('');
        setSelectedPaymentModel('');
        setIconFile(null);
        setUploadError('');
      }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
      <div className="modalBackground">
        <div className="modalContainer">
          <h2>Write about your product:</h2>
          <textarea
            value={localAboutPostText}
            onChange={(e) => setLocalAboutPostText(e.target.value)}
            rows="5"
            cols="50"
            placeholder="About the product (10 - 50 characters)"
          />

          <h2>Product Features:</h2>
          <textarea
            value={localFeaturesText}
            onChange={(e) => setLocalFeaturesText(e.target.value)}
            rows="5"
            cols="50"
            placeholder="Product Features (50 - 500 characters)"
          />

          <h2>Select Payment Model:</h2>
          <select
            value={selectedPaymentModel}
            onChange={(e) => setSelectedPaymentModel(e.target.value)}
          >
            <option value="" disabled>
              Select a payment model
            </option>
            <option value="Freemium">Freemium</option>
            <option value="Lifetime Access">Lifetime Access</option>
            <option value="Subscription">Subscription</option>
          </select>

          <h2>Upload Icon (optional):</h2>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            onChange={handleFileChange}
          />
          {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}

          <div className="modalButtons">
            <button onClick={handlePost} disabled={!isValid}>
              Post
            </button>
            <button onClick={onCancel}>Cancel</button>
          </div>

          {!isValid && (
            <p style={{ color: 'red' }}>
              "About Product" should be 10-50 chars, "Features" 50-500 chars, and
              you must select a Payment Model.
            </p>
          )}
        </div>
      </div>
    );
  }

  // ----------
  //  LIFECYCLE
  // ----------
  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // -----------
  //  TEXT PARSER
  // -----------
  const criteriaList = [
    'Design Foundations',
    'Visual Hierarchy',
    'Layout and Spacing',
    'Color',
    'Typography',
    'Theory',
    'Depth',
    'Design Assets',
    'Details'
  ];

  const processTextResponse = (textResponse) => {
    if (!textResponse) return {};
  
    // Split lines robustly
    const responseLines = textResponse.split(/\r?\n/);
  
    const sections = {};
    let currentSectionTitle = null;
    let currentText = [];
    let currentScore = null;
  
    responseLines.forEach((rawLine) => {
      let line = rawLine.trim();
  
      // Remove leading ### if present
      if (line.startsWith('###')) {
        line = line.replace(/^###\s*/, '');
      }
  
      // If line is "Score: 65", capture the score but don't discard the line
      const separateScore = line.match(/^Score:\s*(\d+(?:\/\d+)?)/i);
      if (separateScore) {
        currentScore = separateScore[1];
        // remove that text from the line
        line = line.replace(separateScore[0], '').trim();
      }
  
      // Check if line starts with a recognized heading
      // Also skip summary if you don’t want it
      let foundTitle =
        criteriaList.find((title) => line.toLowerCase().startsWith(title.toLowerCase())) ||
        (line.toLowerCase().startsWith('overall feedback') ? 'Overall Feedback' : null) ||
        (line.toLowerCase().startsWith('summary') ? 'Summary' : null);
  
      if (foundTitle) {
        // If we were in a prior section, finalize it
        if (currentSectionTitle && currentSectionTitle !== 'Summary') {
          sections[currentSectionTitle] = {
            text: currentText.join('\n'),
            score: currentScore
          };
        }
  
        // Skip summary headings
        if (foundTitle === 'Summary') {
          currentSectionTitle = null;
          currentText = [];
          currentScore = null;
          return;
        }
  
        currentSectionTitle = foundTitle;
        currentText = [];
        currentScore = null;
  
        // remove heading from the line text
        line = line.slice(foundTitle.length).trim();
  
        // If there's an inline "Score: XX" (e.g. "Depth Score: 60"), handle that
        const inlineScore = line.match(/score:\s*(\d+(?:\/\d+)?)/i);
        if (inlineScore) {
          currentScore = inlineScore[1];
          line = line.replace(inlineScore[0], '').trim();
        }
      } else {
        // Maybe line is "Score: 60" plus text. We can detect that too, if needed
        const inlineScore = line.match(/score:\s*(\d+(?:\/\d+)?)/i);
        if (inlineScore) {
          currentScore = inlineScore[1];
          line = line.replace(inlineScore[0], '').trim();
        }
      }
  
      // Add leftover text to the current section
      if (currentSectionTitle && line) {
        currentText.push(line);
      }
    });
  
    // finalize last section
    if (currentSectionTitle && currentSectionTitle !== 'Summary') {
      sections[currentSectionTitle] = {
        text: currentText.join('\n'),
        score: currentScore
      };
    }
  
    return sections;
  };
  // -----------
  //  DELETE HELPER
  // -----------
  const deleteResponse = async (id) => {
    const user = auth.currentUser;
    if (user) {
      const responseRef = doc(db, `history/${user.uid}/responses`, id);
      try {
        await updateDoc(responseRef, {
          saveResponse: false
        });
        console.log(`Save response for ${id} updated to false`);
        fetchResponses();
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    }
  };

  // -----------
  //  POST HELPER
  // -----------
  const togglePost = async (id, currentPostStatus) => {
    if (currentPostStatus) {
      // If already posted, toggling it "unposts" it
      const user = auth.currentUser;
      if (user) {
        const responseRef = doc(db, `history/${user.uid}/responses`, id);
        try {
          await updateDoc(responseRef, {
            postResponse: !currentPostStatus
          });
          console.log(`Post status for ${id} updated to: ${!currentPostStatus}`);
          fetchResponses();
        } catch (error) {
          console.error('Error updating document: ', error);
        }
      }
    } else {
      // If not posted yet, open the "PostModal"
      setCurrentPostId(id);
      setShowPostModal(true);
    }
  };

  // Called when user confirms "Post"
  const confirmPost = async ({ aboutPost, featuresPost, paymentModel, iconPostUrl }) => {
    if (currentPostId && aboutPost) {
      const user = auth.currentUser;
      if (user) {
        const responseRef = doc(db, `history/${user.uid}/responses`, currentPostId);
        try {
          await updateDoc(responseRef, {
            postResponse: false,
            aboutPost,
            featuresPost,
            paymentModel,
            postReview: true, // Mark the post for review
            iconPostUrl: iconPostUrl || '' // store icon URL in Firestore
          });
          console.log(
            `Post updated with aboutPost: ${aboutPost}, featuresPost: ${featuresPost}, paymentModel: ${paymentModel}, iconPostUrl: ${iconPostUrl}, postReview: true`
          );
          fetchResponses();
        } catch (error) {
          console.error('Error updating document: ', error);
        }
      }
      setShowPostModal(false);
      setCurrentPostId(null);
    }
  };

  const cancelPost = () => {
    setShowPostModal(false);
    setAboutPostText('');
    setCurrentPostId(null);
  };

  // ----------
  //  RENDER
  // ----------
  return (
    <div className="dashboard">
      {isLoading ? (
        <div className="loader">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      ) : responses.length === 0 ? (
        <div className="emptyState">
          <h2>You haven't any saved posts</h2>
        </div>
      ) : (
        responses.map((response) => (
          <div key={response.id} className="responseCard">
            <div className="titleInformationBox">
              <div className="date">Author: {response.email}</div>
              <div className="date">{response.siteUrl}</div>
              <div className="datetime">
                {response.dateResponse?.toDate().toLocaleString()}
              </div>
            </div>
            <div className="divider"></div>
            <div className="responseCardSections">
              <div className="responseBoxBoard">
                <div className="responseImageBox">
                  <img
                    src={response.imageUrl}
                    alt="Response Screenshot"
                    className="responseImage"
                  />
                </div>
              </div>

              <div className="responseBoxBoardRight">
                {Object.entries(response.parsedTextResponse).map(
                  ([key, { text, score }], index) => (
                    <div key={index} className="responseBox">
                      <div className="overallScoreBox">
                        <h3>{key}</h3>
                        {score && (
                          <span className="scoreStyle">
                            <CircularScore score={score} />
                          </span>
                        )}
                      </div>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          fontSize: '1rem',
                          textAlign: 'justify'
                        }}
                      >
                        {text}
                      </p>
                    </div>
                  )
                )}
              </div>

              {/* Delete Confirmation Modal */}
              <DeleteConfirmationModal
                isOpen={showDeleteModal}
                onDelete={confirmDelete}
                onCancel={cancelDelete}
              />

              {/* Post Modal (for "Post" or "Unpost") */}
              <PostModal
                isOpen={showPostModal}
                onPost={confirmPost}
                onCancel={cancelPost}
              />
            </div>

            {/* Action buttons */}
            <div className="actionPostButtons">
              <div
                className="actionButton"
                onClick={() => togglePost(response.id, response.postResponse)}
              >
                {response.postResponse ? 'Unpost' : 'Post'}
              </div>
              <div className="actionButton">Share</div>
              <div className="actionButton" onClick={() => promptDelete(response.id)}>
                Delete
              </div>
            </div>

            {/* Show like count if posted */}
            {response.postResponse && (
              <div className="likes">Likes: {response.likeResponse || 0}</div>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default Dashboard;
