import React, { useEffect, useRef, useState } from 'react';
import './LandingTest.css';
import Logo from './images/Logo.png';
import Arrow from './images/Arrow.png';
import Sample from './images/man-characters.webp';
import Lottie from 'react-lottie';
import animationData from './images/mainAnim2.json';
import animationDataDog from './images/doggy.json';
import ScreenHome from './images/ScreenHome.jpg';
import CardImage from './images/robot.webp';
import CardImage2 from './images/dashboard.webp';
import ParallaxSpace from './ParallaxSpace'; // Import the ParallaxSpace component
import './ParallaxSpace.css'; // Create a separate CSS file for styles
import { useNavigate } from 'react-router-dom';
import CircularScore from './CircularScore';


function Landingtest() {
    const [doggyTranslate, setDoggyTranslate] = useState(100); // State for controlling doggy position
    const elementsRef = useRef([]);
    // We'll store the animation frame ID here so we can cancel it
    const animationFrameIdRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Apply the animation to each element
        elementsRef.current.forEach((el, index) => {
            if (el) {
                el.classList.add('animated');
            }
        });
    }, []);

    const canvasRef = useRef(null);
    const stars = [];


    const createStars = (numStars) => {
        for (let i = 0; i < numStars; i++) {
            stars.push({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                radius: Math.random() * 1.5 + 0.5,
                speed: Math.random() * 0.5 + 0.2,
            });
        }
    };

    const drawStars = (ctx) => {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        stars.forEach((star) => {
            ctx.beginPath();
            ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
            ctx.fillStyle = 'white'; // Star color
            ctx.fill();
            // Move stars downward with speed
            star.y += star.speed;
            if (star.y > window.innerHeight) {
                star.y = 0;
                star.x = Math.random() * window.innerWidth;
            }
        });
    };

    // 3) Animation loop
    const animate = () => {
        // Double-check canvas is still mounted
        if (!canvasRef.current) {
            return; // If unmounted, just stop
        }
        const ctx = canvasRef.current.getContext('2d');
        if (!ctx) {
            return; // If we have no context, stop
        }

        drawStars(ctx);
        animationFrameIdRef.current = requestAnimationFrame(animate);
    };

    // 4) On component mount
    useEffect(() => {
        // Animate your elements
        elementsRef.current.forEach((el) => {
            if (el) {
                el.classList.add('animated');
            }
        });
    }, []);

    // 5) Star creation + animation start
    useEffect(() => {
        createStars(100); // Adjust the number of stars as needed
        animationFrameIdRef.current = requestAnimationFrame(animate);

        // Cleanup: cancel the animation frame
        return () => {
            if (animationFrameIdRef.current) {
                cancelAnimationFrame(animationFrameIdRef.current);
            }
        };
    }, []);
    return (
        <div className='landMainTest'>
            <div className='landHeaderTest animate-fade-drop animate-delay-1' ref={(el) => elementsRef.current[0] = el}>
                <div className='logoArea'>
                    <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
                </div>
                <div className='landButtons'>
                    <a href='' className='menuButton'>About</a>
                    <a href='' className='menuButton'>How it works</a>
                    <span onClick={() => navigate('/signup')} className='buttonSignUp'
                        onMouseEnter={() => setDoggyTranslate(-40)} // Move doggy on hover
                        onMouseLeave={() => setDoggyTranslate(100)}>Get started free</span>
                    <span onClick={() => navigate('/signin')} className='buttonSignIn'>Sign in</span>
                </div>
            </div>
            <div className="parallax-background-test">
                <canvas
                    ref={canvasRef}
                    width={window.innerWidth}
                    height={window.innerHeight}

                />
                <div className='boardBoxB '>
                    <div className='boardBoxC'>
                        <div className='boardBoxTest animate-fade-drop animate-delay-2' ref={(el) => elementsRef.current[1] = el}>
                            <div className='leftBoardBoxTest'>
                                <h1 className='productTitleTest'>Revolutionizing Product Insights with AI-Driven Scoring & Analysis</h1>
                                <div className='descProductTextTest'>
                                    Through Advanced Scoring Dashboards, Users Can Analyze, Compare, and Share Products, Making Data-Driven Decisions Without Hassle
                                </div>
                                <div className='boxCenterButtonTest'>
                                    <div className='waitlistButtonTest' onClick={() => navigate('/singup')} onMouseEnter={() => setDoggyTranslate(-40)} // Move doggy on hover
                                        onMouseLeave={() => setDoggyTranslate(100)}>Start now</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='boardBoxATest'>
                <div>
                    <h3>CONNECT, SCORE, SHARE AND BUILD YOUR COMMUNITY</h3>
                    <h1>Exceptional Product Insights with Custom Scoring, Shareability, and Transparency</h1>
                </div>
                <div className='middleBoxTest'>
                    <div className='middleBoxDiv'>
                        <div style={{fontSize: "0.8rem", fontWeight: "500"}}>100+ products estimated</div>
                        <div><p>With ScoreMe.io, users can evaluate products, access actionable insights, and share scores publicly without complex spreadsheets or guesswork.</p></div>
                        <img src={Sample} />
                    </div>
                    <div className='middleBoxDivTwo'>
                        <div class="cards-container-Test">
                        
                            <div class="card-Test summary-Test">
                                <h3>Generating Product Insights</h3>
                                <div class="stats-Test">
                                    <div class="stat-Test">
                                        <span class="value-Test">95%</span>
                                        <span class="label-Test">User Satisfaction</span>
                                    </div>
                                    <div class="stat-Test">
                                        <span class="value-Test">82%</span>
                                        <span class="label-Test">Sharing Engagement</span>
                                    </div>
                                </div>
                            </div>

                            <div class="card-Test video-Test">
                                
                            <p style={{textAlign: "start"}}>
                                    ScoreMe.io combines advanced algorithms and intuitive dashboards to empower users to
                                    make confident product decisions with real-time insights.
                                </p>
                                
                                <iframe style={{borderRadius: "20px"}} width="560" height="315" src="https://www.youtube.com/embed/ym7TqSfrFpY?si=-X8pTcK6HCeZQoF-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                
                                <p style={{textAlign: "start", width: "100%"}}>
                                    <strong>Engagement Metrics:</strong> Products 72%, Public Scores 28%
                                </p>
                            </div>

                            <div class="card-Test new-user-Test">
                            <div className='cardImgBlock'>
                                    <img src={CardImage}/>
                                </div>
                                <div>
                                <h3>Scores Generated</h3>
                                <div >
                                    <span class="value-Test">12k+</span>
                                </div>
                                <span class="services-button-Test">ALL TOOLS</span>
                                </div>
                                
                            </div>

                           
                        </div>

                    </div>
                </div>
            </div>

            <div className='footerLand'>
                <div className='footerRows'>
                    <div className='footerColumns'>
                        <div className='footerRow'>
                            <div className='logoFooter'>
                                <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
                            </div>
                            <div>
                                ScoreMe.io is the ultimate platform for product evaluation and discovery. Our tool allows you to score and rate digital and physical products based on objective criteria, powered by AI insights. Whether you're a creator, entrepreneur, or consumer, you can easily share your product feedback, connect with our community, and gain visibility for your innovations. Post your products publicly, get likes, and rise in popularity with our Hall of Fame feature. Join ScoreMe.io today and make your product stand out in a competitive market!
                            </div>
                        </div>
                        <div className='footerRow'>
                            <div className='menuButtonItem'>
                                <b>Score Me</b>
                            </div>
                            <div className='menuButtonItem'>
                                How it works
                            </div>
                            <div className='menuButtonItem'>
                                Score digital products
                            </div>
                            <div className='menuButtonItem' onClick={() => navigate('/terms-and-conditions')}>
                                Terms & Conditions
                            </div>
                            <div className='menuButtonItem' onClick={() => navigate('/privacy')}>
                                Privacy Policy
                            </div>
                            <div className='menuButtonItem'>
                                Feed
                            </div>
                        </div>
                        <div className='footerRow'>
                            <div>
                                <b>Social Networks</b>
                            </div>
                            <div className='menuButtonItem'>
                                <a href='https://x.com/ScoreMeIo'>X</a>
                            </div>
                            <div className='menuButtonItem'>
                                Instagram
                            </div>
                            <div className='menuButtonItem'>
                                Facebook
                            </div>
                            <div className='menuButtonItem'>
                                Email
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    © 2024 ScoreMe.io™. All Rights Reserved. Privacy Policy
                </div>
            </div>
        </div>
    );
}

export default Landingtest;
