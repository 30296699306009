import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc
} from 'firebase/firestore';

import './Dashboard.css';
import { FaHeart, FaComment, FaSortAlphaUp, FaSortAlphaDown, FaCrown } from 'react-icons/fa';
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json';
import CircularScore from './CircularScore'; // Your existing CircularScore component

import Modal from './Modal'; // Import the modal component
import { FaPeopleGroup, FaPerson } from 'react-icons/fa6';

function Feed() {
  const [responses, setResponses] = useState([]);
  const [topPicks, setTopPicks] = useState([]);
  const [sortMode, setSortMode] = useState('dateDesc');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Sort Options');
  const [isLoading, setIsLoading] = useState(false);
  const [topWidget, setTopWidget] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');
  const [founderPhotoURL, setFounderPhotoURL] = useState(<FaPerson />);

  const getUserPhotoURL = async (ownerUid) => {
    const user = auth.currentUser;
    if (user && user.uid === ownerUid) {
      const googlePhoto = user.providerData.find(
        (p) => p.providerId === 'google.com'
      )?.photoURL;
      const twitterPhoto = user.providerData.find(
        (p) => p.providerId === 'twitter.com'
      )?.photoURL;
      if (googlePhoto) return googlePhoto;
      if (twitterPhoto) return twitterPhoto;
    }

    // If not the current user or no provider photo, try Firestore "users" doc
    try {
      const userDocRef = doc(db, 'users', ownerUid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        return docSnap.data().avatarUserUrl || '/placeholder-icon.png';
      }
    } catch (err) {
      console.error('Error fetching user photo from Firestore:', err);
    }

    // Return a fallback string URL
    return '/placeholder-icon.png';
  };

  // The currently selected response for the modal
  const [selectedResponse, setSelectedResponse] = useState(null);

  // COMMENTS STATE (for the modal)
  const [comments, setComments] = useState([]);
  const [newCommentText, setNewCommentText] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState('');

  // Get current month name
  const getCurrentMonthName = () => {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[new Date().getMonth()];
  };

  // Toggle sort dropdown
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle pick from dropdown
  const handleOptionClick = (value, label) => {
    handleSortChange(value);
    setSelectedOption(label);
    setIsOpen(false);
  };

  // Sort mode change
  const handleSortChange = (newSortMode) => {
    setSortMode(newSortMode);
  };

  // ------------------
  // FETCH: TOP PICKS
  // ------------------
  const fetchTopPicks = useCallback(async () => {
    try {
      const usersRef = collection(db, 'users');
      const userSnapshots = await getDocs(usersRef);
      let allResponses = [];

      for (const userDoc of userSnapshots.docs) {
        const userId = userDoc.id;
        const responsesRef = collection(db, `history/${userId}/responses`);
        const responseSnapshots = await getDocs(query(responsesRef, orderBy('likeResponse', 'desc')));

        for (const docSnap of responseSnapshots.docs) {
          const responseData = { id: docSnap.id, ownerId: userId, ...docSnap.data() };
          allResponses.push(responseData);
        }
      }

      const sortedResponses = allResponses
        .sort((a, b) => b.likeResponse - a.likeResponse)
        .slice(0, 3);

      setTopPicks(sortedResponses);
    } catch (error) {
      console.error('Error fetching top products:', error);
    }
  }, []);

  // ---------------------
  // FETCH: TOP WIDGET
  // ---------------------
  const fetchTopWidgetSetting = useCallback(async () => {
    try {
      const controlPanelRef = doc(db, 'controlPanel', '8SGFUGkF19XbA9LgkVfj');
      const docSnap = await getDoc(controlPanelRef);
      if (docSnap.exists()) {
        setTopWidget(docSnap.data().topWidget || false);
      } else {
        console.log('No such document in controlPanel!');
      }
    } catch (error) {
      console.error('Error fetching topWidget setting:', error);
    }
  }, []);

  // --------------------------------
  // FETCH: MAIN FEED + COMMENT COUNTS
  // --------------------------------
  const fetchResponses = useCallback(async () => {
    setIsLoading(true);
    let allResponses = [];

    try {
      const usersRef = collection(db, 'users');
      const snapshot = await getDocs(usersRef);
      const userIds = snapshot.docs.map((doc) => doc.id);

      for (let userId of userIds) {
        const q = query(
          collection(db, `history/${userId}/responses`),
          where('postResponse', '==', true)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) continue;

        // Build array of responses
        for (const docSnap of querySnapshot.docs) {
          const responseData = { id: docSnap.id, ownerId: userId, ...docSnap.data() };
          // Mark if current user liked
          responseData.isLiked = responseData.userLikes?.includes(auth.currentUser?.uid);

          allResponses.push(responseData);
        }
      }

      // Now for each response, fetch the comment subcollection count
      // (Expensive approach if you have many docs!)
      for (const resp of allResponses) {
        const commentsRef = collection(
          db,
          `history/${resp.ownerId}/responses/${resp.id}/comments`
        );
        const commentsSnap = await getDocs(commentsRef);
        // store commentCount in the response object
        resp.commentCount = commentsSnap.size;
      }

      // Sorting
      switch (sortMode) {
        case 'popularityDesc':
          allResponses.sort((a, b) => b.likeResponse - a.likeResponse);
          break;
        case 'popularityAsc':
          allResponses.sort((a, b) => a.likeResponse - b.likeResponse);
          break;
        case 'dateDesc':
          allResponses.sort((a, b) => b.dateResponse.toDate() - a.dateResponse.toDate());
          break;
        case 'dateAsc':
          allResponses.sort((a, b) => a.dateResponse.toDate() - b.dateResponse.toDate());
          break;
        case 'alphabetAsc':
          allResponses.sort((a, b) => a.siteUrl.localeCompare(b.siteUrl));
          break;
        case 'alphabetDesc':
          allResponses.sort((a, b) => b.siteUrl.localeCompare(a.siteUrl));
          break;
        default:
          // default to dateDesc
          allResponses.sort((a, b) => b.dateResponse.toDate() - a.dateResponse.toDate());
      }

      setResponses(allResponses);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching responses:', error);
      setIsLoading(false);
    }
  }, [sortMode]);

  // ---------------
  // USE EFFECTS
  // ---------------
  useEffect(() => {
    fetchTopWidgetSetting();
    fetchTopPicks();
  }, [fetchTopWidgetSetting, fetchTopPicks]);

  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  useEffect(() => {
    setCurrentMonth(getCurrentMonthName());
  }, []);

  // ---------------
  // LIKE A RESPONSE
  // ---------------
  const addLikeToResponse = async (responseId, ownerId) => {
    const user = auth.currentUser;
    if (!user) return;

    const responseRef = doc(db, `history/${ownerId}/responses`, responseId);
    const docSnap = await getDoc(responseRef);
    if (!docSnap.exists()) return;

    const data = docSnap.data();
    let userLikes = data.userLikes || [];
    let newLikeResponse = data.likeResponse || 0;

    if (userLikes.includes(user.uid)) {
      // unlike
      userLikes = userLikes.filter((uid) => uid !== user.uid);
      newLikeResponse--;
    } else {
      // like
      userLikes.push(user.uid);
      newLikeResponse++;
    }

    await updateDoc(responseRef, {
      userLikes,
      likeResponse: newLikeResponse
    });

    // Update local state
    setResponses((prev) =>
      prev.map((resp) =>
        resp.id === responseId
          ? { ...resp, likeResponse: newLikeResponse, isLiked: !resp.isLiked }
          : resp
      )
    );
  };

  // ----------------
  // COMMENTS LOGIC
  // ----------------
  // fetch comments for selected post
  const fetchComments = async (response) => {
    try {
      const commentsRef = collection(
        db,
        `history/${response.ownerId}/responses/${response.id}/comments`
      );
      const q = query(commentsRef, orderBy('createdAt', 'asc'));
      const snapshot = await getDocs(q);

      const allComments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(allComments);
    } catch (err) {
      console.error('Error fetching comments:', err);
    }
  };

  const addComment = async () => {
    if (!selectedResponse) return;
    const user = auth.currentUser;
    if (!user) return;

    const text = newCommentText.trim();
    if (!text) return;

    try {
      const commentsRef = collection(
        db,
        `history/${selectedResponse.ownerId}/responses/${selectedResponse.id}/comments`
      );
      await addDoc(commentsRef, {
        text,
        createdAt: new Date(),
        userId: user.uid,
        userEmail: user.email,
        likes: 0,
        likedBy: []
      });
      setNewCommentText('');
      fetchComments(selectedResponse);
      // Optionally: Re-fetch the comment count for this post and update local state
    } catch (err) {
      console.error('Error adding comment:', err);
    }
  };

  const likeComment = async (commentId) => {
    if (!selectedResponse) return;
    const user = auth.currentUser;
    if (!user) return;

    const commentRef = doc(
      db,
      `history/${selectedResponse.ownerId}/responses/${selectedResponse.id}/comments`,
      commentId
    );
    const snap = await getDoc(commentRef);
    if (!snap.exists()) return;

    let { likes, likedBy } = snap.data();
    if (!likedBy) likedBy = [];
    if (!likes) likes = 0;

    if (likedBy.includes(user.uid)) {
      // Unlike
      likedBy = likedBy.filter((uid) => uid !== user.uid);
      likes--;
    } else {
      // Like
      likedBy.push(user.uid);
      likes++;
    }

    await updateDoc(commentRef, {
      likes,
      likedBy
    });

    fetchComments(selectedResponse);
  };

  const deleteComment = async (commentId) => {
    if (!selectedResponse) return;
    const user = auth.currentUser;
    if (!user) return;

    const commentRef = doc(
      db,
      `history/${selectedResponse.ownerId}/responses/${selectedResponse.id}/comments`,
      commentId
    );
    await deleteDoc(commentRef);
    fetchComments(selectedResponse);
  };

  const editComment = (comment) => {
    setEditingCommentId(comment.id);
    setEditCommentText(comment.text);
  };

  const confirmEditComment = async (commentId) => {
    if (!selectedResponse) return;
    const user = auth.currentUser;
    if (!user) return;

    const newText = editCommentText.trim();
    if (!newText) return;

    const commentRef = doc(
      db,
      `history/${selectedResponse.ownerId}/responses/${selectedResponse.id}/comments`,
      commentId
    );
    await updateDoc(commentRef, { text: newText });

    setEditingCommentId(null);
    setEditCommentText('');
    fetchComments(selectedResponse);
  };

  const cancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText('');
  };

  // ----------------
  // MODAL HANDLERS
  // ----------------
  const openModal = (response) => {
    setSelectedResponse(response);
    fetchComments(response);

    // Fetch the profile photo of the user who created this post:
    getUserPhotoURL(response.ownerId)
      .then((photoURL) => {
        setFounderPhotoURL(photoURL);
      })
      .catch((err) => {
        console.error('Error fetching founder photo:', err);
        setFounderPhotoURL(<FaPerson />);
      });
  };

  const closeModal = () => {
    setSelectedResponse(null);
    setFounderPhotoURL(<FaPerson />); // reset or fallback
    setComments([]);
    setNewCommentText('');
    setEditingCommentId(null);
    setEditCommentText('');
  };

  // ---------------
  // RENDER
  // ---------------
  return (
    <div className="dashboard">
      {isLoading ? (
        <div className="loader">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
            }}
            height={400}
            width={400}
          />
        </div>
      ) : (
        <>
          {/* TOP PICKS WIDGET */}
          {topWidget && (
            <div className="top-picks-widget">
              <h3>Top {currentMonth}</h3>
              <div className="top-picks-list">
                {topPicks.map((product, index) => (
                  <div
                    key={product.id}
                    className={`top-pick-item position-${index + 1}`}
                  >
                    {index === 0 && <FaCrown className="crown-icon" />}
                    <img
                      src={product.avatarUserUrl || '/placeholder-icon.png'}
                      alt="User"
                      className="user-photo"
                    />
                    <div className="top-pick-info">
                      <div className="username">
                        {product.siteUrl
                          ? new URL(product.siteUrl).hostname.replace('www.', '')
                          : 'No siteUrl'}
                      </div>
                      <div className="likes-count">{product.likeResponse || 0}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* SORT DROPDOWN */}
          <div className="dropOption">
            <div className="selectedOptionDrop" onClick={toggleDropdown}>
              {selectedOption}
            </div>
            {isOpen && (
              <div className="dropdownSorting">
                <div
                  className="selectedOptionDropItem"
                  onClick={() => handleOptionClick('dateDesc', 'Date')}
                >
                  Date <FaSortAlphaDown />
                </div>
                <div
                  className="selectedOptionDropItem"
                  onClick={() => handleOptionClick('dateAsc', 'Date')}
                >
                  Date <FaSortAlphaUp />
                </div>
                <div
                  className="selectedOptionDropItem"
                  onClick={() =>
                    handleOptionClick('popularityDesc', 'Most Popular')
                  }
                >
                  Most Popular <FaSortAlphaDown />
                </div>
                <div
                  className="selectedOptionDropItem"
                  onClick={() =>
                    handleOptionClick('popularityAsc', 'Most Popular')
                  }
                >
                  Most Popular <FaSortAlphaUp />
                </div>
                <div
                  className="selectedOptionDropItem"
                  onClick={() => handleOptionClick('alphabetDesc', 'Alphabet')}
                >
                  Alphabet <FaSortAlphaDown />
                </div>
                <div
                  className="selectedOptionDropItem"
                  onClick={() => handleOptionClick('alphabetAsc', 'Alphabet')}
                >
                  Alphabet <FaSortAlphaUp />
                </div>
              </div>
            )}
          </div>

          {/* MAIN RESPONSES LIST */}
          <div className="responses-list">
            {responses.map((response) => {
              const domainName = response.siteUrl
                ? new URL(response.siteUrl).hostname.replace('www.', '')
                : 'No siteUrl';

              return (
                <div
                  key={response.id}
                  className="response-card-minimal"
                  onClick={() => openModal(response)}
                >
                  <div className="response-card-left">
                    <img
                      src={response.iconPostUrl || '/placeholder-icon.png'}
                      alt="icon"
                      className="response-icon"
                    />
                    <div className="response-name-div">
                      <span className="response-name">{domainName}</span>
                      <span className="response-description">{response.aboutPost}</span>
                    </div>
                  </div>
                  <div className="response-card-right">
                    {/* Likes */}
                    <div className="comment-count" onClick={(e) => {
                      e.stopPropagation();
                      addLikeToResponse(response.id, response.ownerId);
                    }}>
                      <button
                        className="like-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          addLikeToResponse(response.id, response.ownerId);
                        }}
                      >
                        <FaHeart className={response.isLiked ? 'active' : ''} />
                        {response.likeResponse || 0}
                      </button>
                    </div>

                    {/* Comments count */}
                    <div
                      className="comment-count"
                      style={{ display: 'flex', alignItems: 'center', gap: '4px', marginLeft: '12px', fontSize: "1rem" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal(response);
                      }}
                    >
                      <FaComment />
                      {response.commentCount || 0}
                    </div>

                    {/* Overall Score */}
                    <span className="response-score">
                      <CircularScore score={response.overallScore} />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          {/* MODAL - FULL POST CONTENT + COMMENTS */}
          {selectedResponse && (
            <Modal onClose={closeModal}>
              <div className="ph-style-modal">
                {/* MODAL HEADER */}
                <div className="ph-modal-header">
                  {/* Left side: product title & tagline */}
                  <div className="ph-modal-header-left">
                    <div className='headerModal'>
                      <img src={selectedResponse.iconPostUrl} />
                      <div className=''>
                        <div className="ph-product-title">{selectedResponse.siteUrl}</div>
                        <div className="ph-product-subtitle">
                          {selectedResponse.aboutPost}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Right side: visit button & upvote button */}
                  <div className="ph-modal-header-right">
                    <a
                      className="ph-visit-button"
                      href={selectedResponse.siteUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visit
                    </a>
                    <button
                      className="ph-upvote-button"
                      onClick={() => addLikeToResponse(selectedResponse.id, selectedResponse.ownerId)}
                    >
                      <FaHeart
                        className={selectedResponse.isLiked ? 'active' : ''}
                        style={{ marginRight: '4px' }}
                      />
                      {selectedResponse.likeResponse || 0}
                    </button>
                  </div>
                </div>

                {/* MAIN CONTENT AREA (Screenshot + Details) */}
                <div className="ph-modal-main">

                  {/* Right: author, about, features, payment, overall score */}
                  <div className="ph-modal-details">
                    <p>
                      {selectedResponse.featuresPost}
                    </p>
                    <p>
                      <strong>Payment Model:</strong> {selectedResponse.paymentModel}
                    </p>
                    <div className='founderLine'>
                      <div className='founderBox'>
                        <FaPeopleGroup /><strong>Founder</strong>
                      </div>
                      <div>
                        {/* Conditionally show user photo OR FaPerson icon */}
                        {founderPhotoURL === '/placeholder-icon.png' ? (
                          <FaPerson style={{ width: '40px', height: '40px' }} />
                        ) : (
                          <img
                            src={founderPhotoURL}
                            alt="Founder"
                            style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                          />
                        )}

                        <p>
                          <strong>Author:</strong> {selectedResponse.email}
                        </p>
                      </div>
                    </div>
                    <div className='ph-screen-block'>
                      <div className="ph-overall-score">
                        <span className="scoreStyle">
                          <CircularScore score={selectedResponse.overallScore} />
                        </span>
                        <h2>Score</h2>
                      </div>
                      {/* Left: screenshot */}
                      <div className="ph-modal-screenshot">
                        <img
                          src={selectedResponse.imageUrl}
                          alt="Screenshot"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* COMMENT SECTION */}
                <div className="ph-modal-comments">
                  <h3>
                    Comments ({comments.length})
                  </h3>

                  {/* Add new comment */}
                  <div className="ph-new-comment">
                    <textarea
                      value={newCommentText}
                      onChange={(e) => setNewCommentText(e.target.value)}
                      placeholder="Write a comment..."
                    />
                    <button onClick={addComment}>Comment</button>
                  </div>

                  {/* Existing comments list */}
                  <div className="ph-comments-list">
                    {comments.map((comment) => {
                      const isOwner = auth.currentUser?.uid === comment.userId;
                      const isEditing = editingCommentId === comment.id;

                      return (
                        <div key={comment.id} className="ph-comment-item">
                          <div className="ph-comment-header">
                            <span className="ph-comment-author">
                              {comment.userEmail}
                            </span> 
                            <span
                              className="ph-comment-like"
                              onClick={() => likeComment(comment.id)}
                            >
                              <FaHeart
                                style={{
                                  color: comment.likedBy?.includes(auth.currentUser?.uid)
                                    ? 'red'
                                    : 'gray',
                                  marginRight: '4px'
                                }}
                              />
                              {comment.likes || 0}
                            </span>
                          </div>

                          {isEditing ? (
                            <div className="ph-comment-edit">
                              <textarea
                                value={editCommentText}
                                onChange={(e) => setEditCommentText(e.target.value)}
                              />
                              <button onClick={() => confirmEditComment(comment.id)}>
                                Save
                              </button>
                              <button onClick={cancelEdit}>Cancel</button>
                            </div>
                          ) : (
                            <p>{comment.text}</p>
                          )}

                          {isOwner && !isEditing && (
                            <div className="ph-comment-actions">
                              <button onClick={() => editComment(comment)}>
                                Edit
                              </button>
                              <button onClick={() => deleteComment(comment.id)}>
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

            </Modal>
          )}

        </>
      )}
    </div>
  );
}

export default Feed;
