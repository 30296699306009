import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", width: "100%"}}>
    
    <div style={{ padding: '20px', lineHeight: '1.6', width: "50%", fontSize: "1rem" }}>
    <div style={{textAlign: "start", fontWeight: "600", cursor: "pointer"}} onClick={() => navigate('/')}>Back</div>
      <h1>Terms & Conditions</h1>
      <p><strong>Last Updated:</strong> 09/01/2025</p>
      <p>
        Welcome to ScoreMe.io. These Terms & Conditions ("Terms") govern your use of our website, services, and applications (collectively, the "Services"). By accessing or using our Services, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you may not use our Services.
      </p>
      <hr />
      <h2>1. About Us</h2>
      <p>
        ScoreMe.io provides tools and resources for scoring, evaluating, and sharing digital products. Our services leverage AI expertise to deliver accurate evaluations and facilitate public sharing of product scores.
      </p>
      <hr />
      <h2>2. Eligibility</h2>
      <p>
        You must be at least 18 years old or have reached the legal age of majority in your jurisdiction to use our Services. By using ScoreMe.io, you represent that you meet this eligibility requirement.
      </p>
      <hr />
      <h2>3. Use of Services</h2>
      <p>You agree to:</p>
      <ul style={{textAlign: "start"}}>
        <li>Use the Services solely for lawful purposes.</li>
        <li>Provide accurate and up-to-date information when creating an account or interacting with our platform.</li>
        <li>Respect intellectual property rights and not misuse or distribute copyrighted content.</li>
      </ul>
      <p>You agree not to:</p>
      <ul style={{textAlign: "start"}}>
        <li>Engage in unauthorized scraping, data mining, or similar activities.</li>
        <li>Attempt to disrupt or compromise the functionality of the Services.</li>
        <li>Use the Services for any fraudulent or deceptive activities.</li>
      </ul>
      <hr />
      <h2>4. Account Management</h2>
      <p>
        To access certain features of ScoreMe.io, you may be required to create an account. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.
      </p>
      <p>
        We reserve the right to suspend or terminate accounts that violate these Terms or are associated with illegal activities.
      </p>
      <hr />
      <h2>5. Intellectual Property</h2>
      <p>
        All content, materials, and software used in the Services, including text, graphics, logos, and AI algorithms, are owned by or licensed to ScoreMe.io and are protected by intellectual property laws. You may not reproduce, distribute, or modify any content from our Services without our prior written consent.
      </p>
      <hr />
      <h2>6. Pricing and Payments</h2>
      <p>
        All pricing for products and services is listed on our website and may change at our discretion. By purchasing through our platform, you agree to our pricing terms and payment policies. Refund and cancellation terms are detailed below.
      </p>
      <hr />
      <h2>7. Refund and Cancellation Policy</h2>
      <p>Refunds and cancellations are handled as follows:</p>
      <ul style={{textAlign: "start"}}>
        <li>Refunds are available for eligible transactions as outlined in our Refund Policy.</li>
        <li>Subscription services can be canceled at any time, but cancellations will not result in a refund for the current billing cycle.</li>
      </ul>
      <hr />
      <h2>8. Limitation of Liability</h2>
      <p>
        ScoreMe.io is not responsible for:
      </p>
      <ul style={{textAlign: "start"}}>
        <li>Errors, omissions, or inaccuracies in product evaluations.</li>
        <li>Losses or damages resulting from reliance on product scores or recommendations.</li>
        <li>Any unauthorized access to your account or personal information.</li>
      </ul>
      <p>Our liability is limited to the fullest extent permitted by law.</p>
      <hr />
      <h2>9. Privacy Policy</h2>
      <p>
        Our Privacy Policy outlines how we collect, use, and protect your information. By using our Services, you agree to our Privacy Policy, which is available <a href="#">here</a>.
      </p>
      <hr />
      <h2>10. Termination</h2>
      <p>
        We may suspend or terminate your access to the Services at any time for violations of these Terms or for other reasons deemed necessary at our discretion.
      </p>
      <hr />
      <h2>11. Governing Law</h2>
      <p>
        These Terms are governed by and construed in accordance with the laws of Ukraine. Any disputes arising from these Terms or your use of the Services will be resolved exclusively in the courts of Ukraine.
      </p>
      <hr />
      <h2>12. Changes to These Terms</h2>
      <p>
        We reserve the right to update or modify these Terms at any time. Changes will be effective upon posting on our website. Continued use of the Services after changes are posted constitutes your acceptance of the new Terms.
      </p>
      <hr />
      <h2>13. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at:
      </p>
      <ul style={{textAlign: "start"}}>
        <li><strong>Email:</strong> <a href="mailto:manager@scoreme.io">manager@scoreme.io</a></li>
      </ul>
      <p>Thank you for using ScoreMe.io!</p>
    </div>
    </div>
  );
};

export default TermsAndConditions;

