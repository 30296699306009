import React, { useState } from "react";
import { auth } from "./firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import "./SignUp.css";
import animationData from './images/signup.json';
import Lottie from "react-lottie";
import { useNavigate } from 'react-router-dom';

const RestorePassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isSent, setIsSent] = useState(false); // Track if the reset email has been sent
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send password reset email
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage(
        "A password reset email has been sent to your email address. Please check your inbox."
      );
      setError(null); // Clear any previous errors
      setIsSent(true); // Update the button state
    } catch (err) {
      setError(err.message);
      setSuccessMessage(null); // Clear any previous success messages
    }
  };

  return (
    <div className="signUpBox">
      <div
        style={{ textAlign: "start", fontSize: "1rem", fontWeight: "600", cursor: "pointer" }}
        onClick={() => navigate('/')}
      >
        Back
      </div>
      <div className="signUpForm">
        <div className="sign-up-form">
          <h2>Restore Your Password</h2>
          <p>Enter your email to reset your password</p>
          {error && <p className="error">{error}</p>}
          {successMessage && <p className="success">{successMessage}</p>}

          <form onSubmit={handleSubmit}>
            <div className="inputDivReg">
              <label>Email address</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isSent} // Disable input if the email has been sent
              />
            </div>
            <button type="submit" disabled={isSent} style={{marginTop: "15px"}}>
              {isSent ? "Email Sent ✔️" : "Reset Password"}
            </button>
          </form>
          <div className="alreadyLogin">
            Remembered your password? <span onClick={() => navigate('/signin')}>Log In</span>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "20px" }}>
          <Lottie
            style={{ height: "auto" }}
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RestorePassword;
