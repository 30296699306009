import React, { useState, useEffect, useRef } from 'react';
import './Landing.css';
import Logo from './images/Logo.png';
import Arrow from './images/Arrow.png';
import Lottie from 'react-lottie';
import animationData from './images/mainAnim2.json';
import { useNavigate } from 'react-router-dom';

function Launch() {
    const [isPopupOpen, setPopupOpen] = useState(false); // State for pop-up
    const elementsRef = useRef([]);
    const navigate = useNavigate();
    const canvasRef = useRef(null);
    const stars = [];

    useEffect(() => {
        // Apply the animation to each element
        elementsRef.current.forEach((el) => {
            if (el) {
                el.classList.add('animated');
            }
        });
    }, []);

    // Function to open the pop-up
    const handleSubscribeClick = () => {
        setPopupOpen(true);
    };

    // Function to close the pop-up
    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const createStars = (numStars) => {
        for (let i = 0; i < numStars; i++) {
            stars.push({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                radius: Math.random() * 1.5 + 0.5,
                speed: Math.random() * 0.5 + 0.2,
            });
        }
    };

    const drawStars = (ctx) => {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        stars.forEach((star) => {
            ctx.beginPath();
            ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
            ctx.fillStyle = 'white'; // Star color
            ctx.fill();

            // Move stars downward with speed
            star.y += star.speed;
            if (star.y > window.innerHeight) {
                star.y = 0;
                star.x = Math.random() * window.innerWidth;
            }
        });
    };

    const animate = () => {
        if (!canvasRef.current) return; // Guard for null ref
        const ctx = canvasRef.current.getContext('2d');
        if (!ctx) return; // Guard for context availability
        drawStars(ctx);
        requestAnimationFrame(animate);
    };

    useEffect(() => {
        createStars(100); // Adjust the number of stars

        const startAnimation = () => {
            if (canvasRef.current) {
                animate();
            }
        };

        // Start the animation only when the canvas is ready
        setTimeout(startAnimation, 0);

        return () => {
            cancelAnimationFrame(animate);
        };
    }, []);

    return (
        <div className="landMain">
            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-content">
                        <button className="close-popup" onClick={handleClosePopup}>
                            x
                        </button>
                        <iframe
                            src="https://cdn.forms-content-1.sg-form.com/eaf32547-9245-11ef-af42-4ea2a92e0087"
                            title="Subscribe Form"
                            width="100%"
                            height="500"
                            frameBorder="0"
                        />
                    </div>
                </div>
            )}
            <div className="parallax-background">
                <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight} />
                <div
                    className="landHeaderLaunch animate-fade-drop animate-delay-1"
                    ref={(el) => (elementsRef.current[0] = el)}
                >
                    <div className="logoArea">
                        <img src={Logo} alt="logo" className="logoImgLaunch" />
                        <span className="logoTextLaunch">ScoreMe.io – Product Scoring & Sharing!</span>
                    </div>
                </div>
                <div className="boardBoxB">
                    <div className="boardBoxC">
                        <div
                            className="boardBox animate-fade-drop animate-delay-2"
                            ref={(el) => (elementsRef.current[1] = el)}
                        >
                            <div className="leftBoardBox">
                                <h1 className="productTitle">
                                    We will score your <span className="productText">Products</span>
                                </h1>
                                <div className="descProductText">
                                    Join us, score your product and share it{' '}
                                    <img src={Arrow} alt="arrow" className="arrow" />
                                </div>
                                <div className="boxCenterButton">
                                    <button className="waitlistButton" onClick={handleSubscribeClick}>
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                            <div className="rightBoardBox">
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: animationData,
                                        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
                                    }}
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='footerLand'>
    <div className='footerRows'>
        <div className='footerColumns'>
        <div className='footerRow'>
            <div className='logoFooter'>
            <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
            </div>
            <div>
            ScoreMe.io is the ultimate platform for product evaluation and discovery. Our tool allows you to score and rate digital and physical products based on objective criteria, powered by AI insights. Whether you're a creator, entrepreneur, or consumer, you can easily share your product feedback, connect with our community, and gain visibility for your innovations. Post your products publicly, get likes, and rise in popularity with our Hall of Fame feature. Join ScoreMe.io today and make your product stand out in a competitive market!
            </div>
        </div>
        <div className='footerRow'>
            <div className='menuButtonItem'>
                <b>Score Me</b>
            </div>
            <div className='menuButtonItem'>
                How it works
            </div>
            <div className='menuButtonItem'>
                Score digital products
            </div>
            <div className='menuButtonItem' onClick={() => navigate('/terms-and-conditions')}>
                Terms & Conditions
            </div>
            <div className='menuButtonItem' onClick={() => navigate('/privacy')}>
                Privacy Policy
            </div>
            <div className='menuButtonItem'>
                Feed
            </div>
        </div>
        <div className='footerRow'>
            <div>
                <b>Social Networks</b>
            </div>
            <div className='menuButtonItem'>
            <a href='https://x.com/ScoreMeIo'>X</a>
            </div>
            <div className='menuButtonItem'>
                Instagram
            </div>
            <div className='menuButtonItem'>
                Facebook
            </div>
            <div className='menuButtonItem'>
                Email
            </div>
        </div>
        </div>
    </div>
    <div>
        © 2024 ScoreMe.io™. All Rights Reserved. Privacy Policy
    </div>
</div>
        </div>
    );
}

export default Launch;
