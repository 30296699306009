import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import './Rate.css';
import { auth, db } from './firebase.js';
import { collection, doc, setDoc, getDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json'; // Adjust the path as necessary
import Feed from './Feed.js';
import CircularScore from './CircularScore.js';
import { decrementRateAmount } from "./firebase"; // Import the decrement function
import { useNavigate } from 'react-router-dom';
import CardImage from './images/cardweb.webp';
import CardImage2 from './images/dashboard.webp';
import CardFeed from './images/feedweb.webp';


function Rate() {
    const [url, setUrl] = useState('');
    const [query, setQuery] = useState('');
    const [image, setImage] = useState('');
    const [error, setError] = useState(null);
    const [visionResponse, setVisionResponse] = useState('');
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [showCriteriaError, setShowCriteriaError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [screenshotUrl, setScreenshotUrl] = useState('');
    const [responseSections, setResponseSections] = useState({});
    const [pageOption, setPageOption] = useState('None');
    const [showPageError, setShowPageError] = useState(false);
    const storage = getStorage();
    const [currentResponseId, setCurrentResponseId] = useState(null);
    const [postSaved, setPostSaved] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        if (visionResponse) {
            const sections = splitResponseIntoSections(visionResponse);
            console.log('Processed Sections:', sections);
            setResponseSections(sections);
        }
    }, [visionResponse]);

    const splitResponseIntoSections = (visionResponseText) => {
        // Split on any type of new line
        const lines = visionResponseText.split(/\r?\n/);

        // We'll store parsed sections here as { heading: { text, score } }
        const sections = {};

        let currentHeading = null;
        let currentScore = null;
        let currentText = [];

        // Helper to finalize current heading in sections
        function finalizeSection() {
            if (currentHeading) {
                sections[currentHeading] = {
                    text: currentText.join('\n'),
                    score: currentScore,
                };
            }
        }

        // Regex: capture "Some Heading Score: 72"
        // - Heading can be multiple words, ignoring leading/trailing "**" or similar markup
        const headingScoreRegex = /^(?:\*{0,2})(.+?)\s*Score:\s*(\d{1,3})(?:\*{0,2})$/i;

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i].trim();
            if (!line) continue; // skip blank lines

            // 1) Does this line match "Heading Score: number"?
            const match = headingScoreRegex.exec(line);
            if (match) {
                // We found a new heading, so finalize the old one
                finalizeSection();
                // Start a new one
                currentHeading = match[1].trim();
                currentScore = match[2];
                currentText = [];
            }
            else {
                // 2) Otherwise, treat as text for the current heading
                if (currentHeading) {
                    currentText.push(line);
                }
            }
        }

        // finalize the last one
        finalizeSection();

        console.log('Final parsed sections:', sections);
        return sections;
    };


    // List of all criteria
    const criteriaList = [
        'Design Foundations',
        'Visual Hierarchy',
        'Layout and Spacing',
        'Color',
        'Typography',
        'Theory',
        'Depth',
        'Design Assets',
        'Details'
    ];

    const toggleCriteria = (criterion) => {
        setSelectedCriteria((prevSelected) => {
            const newSelection = prevSelected.includes(criterion)
                ? prevSelected.filter((item) => item !== criterion)
                : [...prevSelected, criterion];
            setShowCriteriaError(newSelection.length === 0);
            return newSelection;
        });
    };

    const fetchImageAndAnalyze = () => {
        setShowPageError(false);
        setShowCriteriaError(false);

        if (pageOption === 'None') {
            setShowPageError(true);
            return;
        }

        if (selectedCriteria.length === 0) {
            setShowCriteriaError(true);
            return;
        }

        setIsLoading(true);
        const encodedUrl = encodeURIComponent(url);
        const fullPage = pageOption === 'Full Page' ? 'true' : pageOption === 'First Screen' ? 'false' : null;

        let viewportWidth = 1920;
        let viewportHeight = 1280;
        let fullPageOption = `&full_page=${fullPage}`;

        if (pageOption === 'Mobile') {
            viewportWidth = 430;
            viewportHeight = 932;
            fullPageOption = `&full_page=true`;
        }

        const apiUrl = `https://api.screenshotone.com/take?access_key=4IgCQfpvvOV8Cw&url=${encodeURIComponent(
            url
        )}&viewport_width=${viewportWidth}&viewport_height=${viewportHeight}&device_scale_factor=1&format=jpg&image_quality=80&block_ads=true&block_cookie_banners=true&block_banners_by_heuristics=false&block_trackers=true&delay=0&timeout=60${fullPageOption}`;

        axios
            .get(apiUrl, { responseType: 'blob' })
            .then(async (response) => {
                const screenshotBlob = response.data;
                const reader = new FileReader();
                reader.readAsDataURL(screenshotBlob);
                reader.onloadend = async () => {
                    const base64Data = reader.result;

                    const storageReference = ref(storage, `screenshots/${Date.now()}_screenshot.jpg`);
                    const snapshot = await uploadBytes(storageReference, screenshotBlob);
                    const downloadURL = await getDownloadURL(snapshot.ref);

                    setScreenshotUrl(downloadURL);
                    setImage(downloadURL);

                    // Construct the text prompt for GPT-4
                    const requestText = constructRequestText();
                    analyzeImage(requestText, downloadURL);
                };
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching and processing screenshot:', error);
                setError(error.message);
                setIsLoading(false);
            });
    };

    /**
  * Handle the full process of rating, including decrementing rateAmount after the last response.
  */
    const handleRateMe = async () => {
        setShowPageError(false);
        setShowCriteriaError(false);

        if (pageOption === "None") {
            setShowPageError(true);
            return;
        }

        if (selectedCriteria.length === 0) {
            setShowCriteriaError(true);
            return;
        }

        setIsLoading(true);

        try {
            const user = auth.currentUser;

            if (user) {
                // Check rateAmount from Firestore
                const userRef = doc(db, "users", user.uid);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const { rateAmount } = userDoc.data();

                    if (rateAmount <= 0) {
                        setError("You don't have enough credentials to generate a response.");
                        setIsLoading(false);
                        return;
                    }
                } else {
                    console.error("User document not found in Firestore.");
                    setIsLoading(false);
                    return;
                }
            } else {
                console.error("No user logged in.");
                setIsLoading(false);
                return;
            }

            // Step 1: Fetch the first response (API 1)
            await fetchImageAndAnalyze();

            // Step 2: Fetch the second response (API 2)
            const secondResponse = await fetchSecondAPIResponse();
            console.log("Second API Response:", secondResponse);

            // Step 3: Decrement rateAmount after both responses are completed
            if (user) {
                await decrementRateAmount(user.uid);
            }
        } catch (error) {
            console.error("Error during rating process:", error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    /**
     * Mock function to simulate the second API response.
     * Replace this with actual logic if needed.
     */
    const fetchSecondAPIResponse = async () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve("Second response received!");
            }, 2000); // Simulate delay
        });
    };

    /**
     * NOTE: Since "gpt-4-vision-preview" is deprecated, we switch to "gpt-4".
     * But "gpt-4" does not truly interpret the image; we only provide the URL as reference text.
     */
    const analyzeImage = (requestText, downloadURL) => {
        setIsLoading(true);

        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer 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` // <--- put your real key
        };

        // Just pass the image URL in the text. GPT-4 (text-based) can’t actually see it, 
        // but we can mention it in context:
        const payload = {
            model: 'gpt-4o-mini', // changed from gpt-4-vision-preview
            messages: [
                {
                    role: 'user',
                    content: `Here's a website screenshot link: ${downloadURL}\n` + requestText
                }
            ],
            max_tokens: 2000
        };

        axios
            .post('https://api.openai.com/v1/chat/completions', payload, {
                headers: headers
            })
            .then((response) => {
                const textResponse = response.data.choices[0].message.content;
                console.log('Response from OpenAI:', textResponse);
                setVisionResponse(textResponse);
                saveResponseToFirestore(textResponse, downloadURL);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error making API request:', error);
                setError(error.message);
                setIsLoading(false);
            });
    };

    const constructRequestText = () => {
        const criteriaText = selectedCriteria.join(', ');
        return `Assess the provided visual as a Product Designer. Please provide theoretical feedback based on the text. The structure of the response without additional words: Title - Score - Advice. The required criteria: Overall Feedback, ${criteriaText}. Write a structured response with each criterion on a new line, each with a Score: 0-100. Example: \nDesign Foundations Score: 80\n[Advice]\n... etc.`;
    };

    const saveResponseToFirestore = async (textResponse, screenshotUrl) => {
        const user = auth.currentUser;
        if (user) {
            const overallScore = extractOverallScore(textResponse);
            const responsesRef = collection(db, `history/${user.uid}/responses`);
            try {
                const docRef = await addDoc(responsesRef, {
                    textResponse,
                    imageUrl: screenshotUrl,
                    dateResponse: serverTimestamp(),
                    postResponse: false,
                    saveResponse: false,
                    likeResponse: 0,
                    siteUrl: url,
                    email: user.email,
                    overallScore: overallScore !== null ? parseInt(overallScore, 10) : null
                });
                console.log('Document written with ID: ', docRef.id);
                setCurrentResponseId(docRef.id);
            } catch (error) {
                console.error('Error adding document: ', error);
            }
        } else {
            console.error('No user logged in!');
        }
    };

    const extractOverallScore = (textResponse) => {
        const overallFeedbackMatch = textResponse.match(/Overall Feedback\s*Score:\s*(\d+)/);
        return overallFeedbackMatch ? overallFeedbackMatch[1] : null;
    };

    const savePost = async () => {
        if (currentResponseId) {
            const responseDocRef = doc(db, `history/${auth.currentUser.uid}/responses`, currentResponseId);
            try {
                await setDoc(responseDocRef, { saveResponse: true }, { merge: true });
                console.log('Post saved successfully!');
                setPostSaved(true);
            } catch (error) {
                console.error('Error saving post: ', error);
            }
        } else {
            console.error('No response ID available to update!');
        }
    };

    return (
        <div className="rateBody">
            <div className="rateUpper">
                <div className="selectorProduct">
                    <div className="pageOption">
                        <label className={`option ${pageOption === 'Full Page' ? 'active' : ''}`}>
                            <input
                                type="radio"
                                value="Full Page"
                                checked={pageOption === 'Full Page'}
                                onChange={(e) => {
                                    setPageOption(e.target.value);
                                    setShowPageError(false);
                                }}
                            />
                            Full Page
                        </label>
                        <label className={`option ${pageOption === 'First Screen' ? 'active' : ''}`}>
                            <input
                                type="radio"
                                value="First Screen"
                                checked={pageOption === 'First Screen'}
                                onChange={(e) => {
                                    setPageOption(e.target.value);
                                    setShowPageError(false);
                                }}
                            />
                            First Screen
                        </label>
                        <label className={`option ${pageOption === 'Mobile' ? 'active' : ''}`}>
                            <input
                                type="radio"
                                value="Mobile"
                                checked={pageOption === 'Mobile'}
                                onChange={(e) => {
                                    setPageOption(e.target.value);
                                    setShowPageError(false);
                                }}
                            />
                            Mobile
                        </label>
                    </div>
                </div>
                {error && <div className="error">{error} <span onClick={() => navigate('/plans')}>Upgrade!</span></div>}
                <div className="inputRateField">
                    <input
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="https://example.com"
                    />


                    <div
                        className="enterButton"
                        onClick={() => {
                            const requestText = constructRequestText();
                            setQuery(requestText);
                            handleRateMe(); // Call the function here
                        }}
                    >
                        Rate me
                    </div>
                </div>
                {showPageError && <div className="error">Choose page option!</div>}
                {showCriteriaError && <div className="error">You should choose at least one option!</div>}

                <div className="criteriaList">
                    {criteriaList.map((criterion, index) => (
                        <div
                            key={index}
                            className={`criteriaItem ${selectedCriteria.includes(criterion) ? 'selected' : ''
                                }`}
                            onClick={() => toggleCriteria(criterion)}
                        >
                            {criterion}
                        </div>
                    ))}
                </div>
            </div>

            <div className="rateResponse">
                {isLoading ? (
                    <div className="loader">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                            }}
                            height={400}
                            width={400}
                        />
                    </div>
                ) : (
                    <>
                        <div className="rateResponseImage">
                            {image && <img src={image} alt="Screenshot" className="productImage" />}
                        </div>
                        <div className="rateResponseText">
                            {currentResponseId && (
                                <div className="saveThisPost">
                                    Save this post?
                                    <span
                                        onClick={!postSaved ? savePost : null}
                                        className="saveThisButton"
                                    >
                                        {postSaved ? 'Post Saved' : 'Yes'}
                                    </span>
                                </div>
                            )}

                            {Object.entries(responseSections).map(([heading, { text, score }], index) => (
                                <div key={index} className="responseBox">
                                    <div className="overallScoreBox">
                                        <h3>{heading}</h3>
                                        {score && (
                                            <span className="scoreStyle">
                                                <CircularScore score={score} />
                                            </span>
                                        )}
                                    </div>
                                    <p style={{ whiteSpace: 'pre-wrap', textAlign: "start" }}>{text}</p>
                                </div>
                            ))}

                        </div>
                    </>
                )}
            </div>
            <div className="customCardsSection">
                <h2 className="customCardsHeader">Why Choose Our Service?</h2>
                <div className="customCardsContainer">
                    <div className="customCard">
                        <div className="customCardContent">
                            <h3>Unmatched Motion Quality</h3>
                            <p>
                                Realistic motion that respects the laws of physics, down to the tiniest of details.
                            </p>
                        </div>
                        <div className="customCardImageWrapper">
                            <img src={CardImage} alt="Unmatched Motion Quality" className="customCardImage" />
                        </div>
                    </div>
                    <div className="customCard">
                        <div className="customCardImageWrapper">
                            <img src={CardImage2} alt="Superior Prompt Adherence" className="customCardImage" />
                        </div>
                        <div className="customCardContent">
                            <h3>Superior Prompt Adherence</h3>
                            <p>
                                Get detailed control over characters, settings, and actions with exceptional alignment
                                of videos with textual prompts.
                            </p>
                        </div>
                    </div>
                    <div className="customCard">

                        <div className="customCardContent">
                            <h3>Superior Prompt Adherence</h3>
                            <p>
                                Get detailed control over characters, settings, and actions with exceptional alignment
                                of videos with textual prompts.
                            </p>
                        </div>
                        <div className="customCardImageWrapper">
                            <img src={CardFeed} alt="Superior Prompt Adherence" className="customCardImage" />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Rate;
